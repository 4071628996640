import { ChannelFrequencyCapType, PushNotificationApp } from "./channelTypes"

const getFrequencyCapHourPeriodSize = (frequencyCap: ChannelFrequencyCapType) => {
  let periodSize = frequencyCap.period.size
  if (frequencyCap.period.type === "DAYS") periodSize *= 24
  return periodSize
}

const getFrequencyCapSize = (frequencyCap: ChannelFrequencyCapType) => {
  const periodSize = getFrequencyCapHourPeriodSize(frequencyCap)
  return frequencyCap.max_count / periodSize
}

export const isLocalFrequencyConflicting = (
  local: ChannelFrequencyCapType & { ignore_channel_frequency_cap: boolean },
  global: ChannelFrequencyCapType,
) => {
  if (local.ignore_channel_frequency_cap) return false
  return getFrequencyCapSize(local) > getFrequencyCapSize(global)
}

export const hasAndroidApp = (appIds: PushNotificationApp["app_ids"]) =>
  appIds.some(appId => appId.includes("android"))
export const hasIosApp = (appIds: PushNotificationApp["app_ids"]) =>
  appIds.some(appId => appId.includes("ios"))
