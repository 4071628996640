import React, { useMemo, useState } from "react"
import create from "zustand"
import { Link, useHistory } from "react-router-dom"

import Button from "components/UI/elements/Button/Button"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { PUSH_NOTIFICATIONS_CHANNEL_NAME } from "sharedConstants"
import { getRoutePath } from "routes"
import IconButton from "components/UI/elements/IconButton/IconButton"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import MarketingContent from "components/UI/components/MarketingContent/MarketingContent"
import {
  MobilePushNotification,
  MobilePushSelectionState,
} from "resources/mobilePushNotification/mobilePushNotificationTypes"
import Paper from "components/UI/elements/Paper"
import SearchField from "components/UI/elements/SearchField"
import {
  useDeleteMobilePushNotification,
  useFetchAllMobilePushNotifications,
} from "resources/mobilePushNotification/mobilePushNotificationQueries"

import styles from "./MobilePushNotificationsList.module.scss"
import { useHasAccess } from "resources/user/currentUserQueries"
import Page from "components/UI/Page/Page"
import {
  useFetchChannels,
  useFetchPushNotificationsChannel,
  useModifyPushNotificationsChannelFrequencyCap,
} from "resources/channel/channelQueries"
import Tippy from "@tippyjs/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ChannelFrequencyCapType } from "resources/channel/channelTypes"
import ChannelFrequencyCap from "components/UI/components/ChannelFrequencyCap/ChannelFrequencyCap"
import { isLocalFrequencyConflicting } from "resources/channel/channelUtils"
import ChannelFrequencyCapModal from "components/UI/components/ChannelFrequencyCapModal/ChannelFrequencyCapModal"
import CampaignSchedulingIcon from "components/UI/components/CampaignSchedulingIcon/CampaignSchedulingIcon"
import Table, { Column, Modified, Name } from "components/Table/Table"

const pageTitle = "Mobile push campaigns"

const useStore = create<MobilePushSelectionState>(set => ({
  orderBy: "name",
  orderDir: "ASC",
  searchTerm: "",
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  setSearchTerm: searchTerm => set({ searchTerm }),
}))

export default function MobilePushNotificationsList() {
  const hasAccess = useHasAccess()

  const [selectedNotification, setSelectedNotification] = useState<MobilePushNotification | null>(
    null,
  )
  const [affectedCampaigns, setAffectedCampaigns] = useState<Array<MobilePushNotification>>([])
  const [frequencyCapToSave, setFrequencyCapToSave] = useState<
    ChannelFrequencyCapType | undefined
  >()

  const history = useHistory()

  const { data: channels, isLoading: isLoadingChannels } = useFetchChannels()
  const notificationsEnabled = channels
    ? channels.find(({ name }) => name === PUSH_NOTIFICATIONS_CHANNEL_NAME)?.is_active
    : false

  const { orderBy, orderDir, searchTerm, setSort, setSearchTerm } = useStore()
  const {
    data,
    isLoading: isLoadingNotifications,
    isFetching: isFetchingNotifications,
  } = useFetchAllMobilePushNotifications(
    {
      enabled: hasAccess.mobilePushNotifications.view && notificationsEnabled,
    },
    { orderBy, orderDir, searchTerm: searchTerm.trim() },
  )

  const { mutate, isLoading: isDeleting } = useDeleteMobilePushNotification()

  const { data: pushNotificationsChannel, isLoading: isLoadingPushNotificationsChannel } =
    useFetchPushNotificationsChannel({
      enabled: hasAccess.setup.channels && notificationsEnabled,
    })

  const { mutate: modifyFrequencyCap, isLoading: isModifyingFrequencyCap } =
    useModifyPushNotificationsChannelFrequencyCap()

  const conflictingCampaigns = useMemo(() => {
    if (!data || !pushNotificationsChannel) return []

    return data.push_notifications.filter(({ frequency_cap }) =>
      isLocalFrequencyConflicting(frequency_cap, pushNotificationsChannel.frequency_cap),
    )
  }, [data, pushNotificationsChannel])

  const closeDeleteModal = () => setSelectedNotification(null)

  const closeFrequencyCapModal = () => {
    setAffectedCampaigns([])
    setFrequencyCapToSave(undefined)
  }

  const submitFrequencyCap = (frequencyCap: ChannelFrequencyCapType) => {
    if (isModifyingFrequencyCap) return

    modifyFrequencyCap(
      { data: frequencyCap },
      {
        onSuccess: () => {
          closeFrequencyCapModal()
        },
      },
    )
  }

  // `isLoading` is true if the query is disabled and stays true. We have to check `isFetching` to
  //  exclude that scenario from this if statement.
  if (isLoadingChannels || (isLoadingNotifications && isFetchingNotifications))
    return (
      <Page title={pageTitle}>
        <LoadingIndicator />
      </Page>
    )

  if (!notificationsEnabled)
    return (
      <Page title={pageTitle}>
        <MarketingContent
          img={
            <div className={styles.marketingContent}>
              <div className={styles.bottomLine} />
              <div data-testid="mobile-push-notifications-phone" className={styles.phone}>
                <div data-testid="mobile-push-notifications-screen" className={styles.screen} />
              </div>
            </div>
          }
        >
          <h1>Push notifications are ready&nbsp;for&nbsp;use!</h1>
          <strong>
            To set this up, please contact{" "}
            <a href="mailto:support@meiro.io" target="_blank" rel="noreferrer">
              support@meiro.io
            </a>{" "}
            or get in touch with your Meiro account manager!
          </strong>
          <p>
            The new channel for activation allows you to combine real-time web personalization{" "}
            <span className={styles.primary}>with mobile app push messages</span>.
          </p>
          <p>Use your audiences to trigger personalized content for your mobile app users.</p>
        </MarketingContent>
      </Page>
    )

  if (!hasAccess.mobilePushNotifications.view)
    return (
      <Page title={pageTitle}>
        <MarketingContent
          img={
            <div className={styles.marketingContent}>
              <div className={styles.bottomLine} />
              <div data-testid="mobile-push-notifications-phone" className={styles.phone}>
                <div data-testid="mobile-push-notifications-screen" className={styles.screen} />
              </div>
            </div>
          }
        >
          <h1>Push notifications for mobile apps</h1>
          <strong>
            It seems like you don't have access to the Mobile Push tab. If you want to know more
            about your access settings, please contact your administrator.
          </strong>
          <p>
            The new channel for activation allows you to combine real-time web personalization{" "}
            <span className={styles.primary}>with mobile app push messages</span>.
          </p>
          <p>Use your audiences to trigger personalized content for your mobile app users.</p>
        </MarketingContent>
      </Page>
    )

  const isEmpty = data?.push_notifications.length === 0

  const columns: Column<MobilePushNotification>[] = [
    {
      id: "name",
      label: "Name",
      gridTemplate: "1fr",
      onSort() {
        setSort("name")
      },
      renderCell: notification => (
        <div className={styles.nameWrapper} data-testid="mobile-push-column-name">
          <CampaignSchedulingIcon schedules={notification.schedules} />
          {notification.frequency_cap.ignore_channel_frequency_cap && (
            <Tippy content="Ignores global frequency cap." placement="bottom">
              <FontAwesomeIcon icon={["fas", "bolt"]} className={styles.boltIcon} />
            </Tippy>
          )}
          {conflictingCampaigns.find(({ id }) => notification.id === id) && (
            <Tippy content="Conflicting with global frequency cap." placement="bottom">
              <FontAwesomeIcon
                icon={["fas", "exclamation-triangle"]}
                className={styles.warningIcon}
              />
            </Tippy>
          )}
          <Name name={notification.name} className={styles.name} />
        </div>
      ),
    },
    {
      id: "last_activation",
      label: "Last activation",
      gridTemplate: "max-content",
      onSort() {
        setSort("last_activation")
      },
      renderCell: notification => (
        <Modified
          modifiedAt={notification.last_activation}
          modifiedBy={notification.last_activation_by}
        />
      ),
    },
    {
      id: "modified",
      label: "Modified at",
      gridTemplate: "max-content",
      onSort() {
        setSort("modified")
      },
      renderCell: notification => (
        <Modified modifiedAt={notification.modified} modifiedBy={notification.modified_by} />
      ),
    },
    {
      id: "actions",
      gridTemplate: "max-content",
      renderCell: notification => (
        <div className={styles.actions}>
          <Link
            to={getRoutePath("channels.mobile-push.detail", {
              id: notification.id,
            })}
            className={styles.editLink}
          >
            <IconButton
              color="black"
              size="xs"
              variant="outlined"
              icon="pencil-alt"
              tooltip="Edit"
              data-testid="edit-mobile-push-notification"
            />
          </Link>
          <IconButton
            disabled={!hasAccess.mobilePushNotifications.edit}
            color="red"
            icon="trash-alt"
            size="xs"
            tooltip="Delete"
            variant="outlined"
            onClick={() => setSelectedNotification(notification)}
            data-testid="remove-mobile-push-notification"
          />
        </div>
      ),
    },
  ]

  return (
    <Page
      title={pageTitle}
      headerContent={
        <>
          <SearchField
            input={{ value: searchTerm, onChange: setSearchTerm }}
            placeholder="Search for name"
            onClear={() => setSearchTerm("")}
            wrapperClassName={styles.search}
          />
          <Button
            disabled={!hasAccess.mobilePushNotifications.edit}
            onClick={() =>
              history.push({
                pathname: getRoutePath("channels.mobile-push.create"),
                state: { goBack: true },
              })
            }
          >
            + Create Mobile Push
          </Button>
        </>
      }
    >
      {pushNotificationsChannel && (
        <ChannelFrequencyCap
          channel="push_notifications"
          description="The rule applies to all mobile push campaigns. It restricts the number of notifications within a specific time interval. The limit is set to the registration token, and if a customer profile has multiple devices, all of them will be considered as a single count towards the global limit."
          disabled={!hasAccess.mobilePushNotifications.edit}
          isLoading={isLoadingPushNotificationsChannel}
          isSaving={isModifyingFrequencyCap}
          conflictingCampaigns={conflictingCampaigns.map(({ id, name }) => ({ id, name }))}
          frequencyCap={pushNotificationsChannel.frequency_cap}
          onSubmit={formValues => {
            if (data?.push_notifications) {
              const conflictingCampaignsToBe = data.push_notifications.filter(({ frequency_cap }) =>
                isLocalFrequencyConflicting(frequency_cap, formValues),
              )

              if (conflictingCampaignsToBe.length > 0) {
                setAffectedCampaigns(conflictingCampaignsToBe)
                setFrequencyCapToSave(formValues)
                return
              }

              submitFrequencyCap(formValues)
            }
          }}
        />
      )}
      <Paper className={styles.paper} dataTestId="mobile-push-notifications-paper">
        <div className={styles.trashButtonWrapper}>
          <Link to={getRoutePath("channels.mobile-push.trash")}>
            <Button color="grey" variant="outlined" icon={["far", "trash-alt"]}>
              Trash
            </Button>
          </Link>
        </div>
        {data?.push_notifications && (
          <Table
            emptyMessage="No mobile pushes found."
            columns={columns}
            data={data.push_notifications}
            sortBy={orderBy}
            sortDir={orderDir}
          />
        )}
      </Paper>

      {isEmpty && searchTerm === "" && (
        <MarketingContent
          img={
            <div className={styles.marketingContent}>
              <div className={styles.bottomLine} />
              <div data-testid="mobile-push-notifications-phone" className={styles.phone}>
                <div data-testid="mobile-push-notifications-screen" className={styles.screen} />
              </div>
            </div>
          }
        >
          <h1>Push notifications for mobile apps</h1>
          <p>
            The new channel for activation allows you to combine real-time web personalization{" "}
            <span className={styles.primary}>with mobile app push messages</span>.
          </p>
          <p>Use your audiences to trigger personalized content for your mobile app users.</p>
        </MarketingContent>
      )}
      <ConfirmModal
        isLoading={isDeleting}
        open={!!selectedNotification}
        action="delete"
        item={selectedNotification?.name}
        title="Are you sure?"
        type="delete"
        what="mobile push"
        handleClose={closeDeleteModal}
        handleConfirm={() => mutate(selectedNotification!, { onSuccess: () => closeDeleteModal() })}
      />
      {affectedCampaigns.length > 0 && (
        <ChannelFrequencyCapModal
          affectedCampaigns={affectedCampaigns.map(({ id, name }) => ({ id, name }))}
          channel="push_notifications"
          isLoading={isModifyingFrequencyCap}
          open={affectedCampaigns.length > 0}
          onSubmit={() => {
            if (frequencyCapToSave) submitFrequencyCap(frequencyCapToSave)
          }}
          onClose={closeFrequencyCapModal}
        />
      )}
    </Page>
  )
}
